@media (max-width: 500px) {
  .loading-container > img {
    position: fixed;
    top: 0;
    left: 0;

    /* Preserve aspet ratio */
    min-width: 100%;
    min-height: 100%;
  }

  .load-bar-container {
    top: 50%;
    width: 80%;
    justify-content: space-evenly;
  }

  .load-snippets {
    width: 100%;
    height: 3em;
  }

  .load-texts {
    font-size: 1.5em;
  }

  .exp-div {
    width: 80%;
    border-radius: 2em/2em;
    top: 5px;
    height: 2.5em;

  }
  
  .exp-p {
    font-size: 1em;
    margin: 0;
  }

  .poem-wrapper {
    width: 90%;
    left: 5%;
  }

  .enter-site,
  .exit-load-btn {
    height: 35px;
    width: 110px;
    border-radius: 8px;
  }

  .welcome-container {
    width: 95%;
    left: 2.5%;
    height: 45%;
  }

  .home-text {
    padding-right: 20px;
  }

  .content {
    width: 95%;
  }

  .btn-p{
    border-radius: 8px;
    opacity: 0.8;
  }

  .whoami-container {
    width: 98%;
    left: 1%;
  }

  .whoami-wrapper {
    margin: 0 3vw;
  }

  .projects-container {
    width: 90%;
    left: 5%;
    height: 80%;
    top: 0;
    display: flex ;
    flex-direction: column;
    justify-content: space-between;
  }

  .project-card{
    margin: 0;
    padding: 11px;
  }

  .project-title{
    font-size: 18px;
    width: 100%;
    text-align: left;
  }

  .project-description{
    font-size: 13px;
  }

  .modal-a{
    width: 100%;
    max-width: 130px;
  }

  .modal-ul-title{
    width: 100%;
    max-width: 130px;
    text-shadow: 0px 0px 14px #ff00e3aa;
    text-align: center;
  
  }

  .modal-li{
    font-size: 14px;  
  }

  .modal-quit{
    background-color: rgba(255,255,255, 0);
    color: white;
    box-shadow: none;
  }

  .modal-btn{
    width: 100%;
    max-width: 130px;
    border-radius: 8px;
  }

  .modal-top-row{
    justify-content: space-around;
    width: 100%;
    padding: 15px;
  }

  .modal-btn-wrapper{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .modal-sidescroll-wrapper{
    width: 90%;
    align-items: center;
    padding-top: 15px;
    justify-content: flex-start;
  }



  .modal-bg {
    width: 100%;
    z-index: 9999;
    left: 0px;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    height: 90%;
  }

  .modal-spine {
    display: none;
  }

  .modal-carousel {
    height: auto;
    width: 95vw;
    float: left;
  }
  .modal-img-coin,
  .modal-img-bioinfop,
  .modal-img-bioinformatizado {
    width: 100%;
    height: 100%;
    display: inline-block;
  }

  .modal-side{
    justify-content: space-around;
  }
 
  .modal-tech {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  }

  .main-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 83%;
    width: 50%;
  }

  .modal-ul-div {
    justify-content: flex-start;
  }

  .project-links {
    height: 100%;
  }

  .secondary-column {
    width: 50%;
  }

  .made-with-container {
    top: 0;
    height: 90%;
    left: 0;
    width: 100%;
    justify-content: space-around;
}
  .made-with-title {
    margin: 5px;
    font-size: 2.25em;
    align-self: center;
  }

  .made-with-wrapper {
    height: auto;
    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 0;
}t

  .made-with-ul {
    width: 100%;
}

  .made-with-header {
    align-self: center;
  }

    .made-with-ul {
      height: auto;
      width: 100%;
      margin-bottom: 1.5%;
  }

  .made-with-li {
    width: 100%;
  }

  .contact-container {
    left: 20%;
    width: 60%;
    height: 100%;
    align-items: center;
  }
  .contact-wrapper {
    margin: 0;
    width: 100%;
    text-align: center;
  }
  .contact-header {
    margin: 25px;
    font-size: 2em;
    text-align: center;
  }
}

@media (max-height: 500px) {
  
  .project-card{
    margin: 0;
    padding: 11px;
    width: 48% ;
  }
  
  .projects-container{
    display: flex;
    left: 0;
  }

  .spine-cards{
    display: none;
  }
  
  .project-logo{
    width: 50px;
    height: 50px;
  }
  
  .project-title{
    width: 100%;
  }
  
  .project-description{
    font-size: 12px;
  }

  .exp-div {
    width: 70%;
    top: 5px;
    height: 1.5em;
  }

  .exp-p {
    font-size: .75em;
    margin: 5px 10px;
    text-align: center;
    color: #74bcff;
    font-weight: 100;
    font-family: -webkit-pictograph;
  }

    .homepage {
      top: 15%;
      height: 70%;
      justify-content: space-between;
    }

    .home {
      margin-bottom: 0;
    }

    .whoami-container {
      top: 25%;
      height: 50%;
  }
  .poem-wrapper {
    top: 5%;
    left: 12.5%;
    height: 75%;
    width: 75%;
}
  .made-with-container {
    top: 0;
    height: 90%;
    right: 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-flow: column;
}
.made-with-wrapper {
  height: 100%;
  align-self: center;
  width: 100%;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-around;
}


.made-with-ul {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  width: 25%;
}


.project-title {
  width: 100%;
  font-size: .9em;
  font-family: 'Work Sans';
}
.project-card {
  margin: 0;
  margin-bottom: 5%;
  padding: 2px;
  width: 33%;
  height: 50%;
}
.projects-container {
  top: 0;
  height: 100%;
  left: 0;
  width: 100%;
  align-items: flex-end;
  justify-content: space-around;
}

.side-scroll-btn {
  width: 25%;
  align-self: flex-end;
}
}
