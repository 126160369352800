@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300&display=swap');
* {
  padding: 0;
  margin: 0;
}
/* http://meyerweb.com/eric/tools/css/reset/ */
/* v1.0 | 2007vw2 */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  font-family: 'Work Sans';
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

p {
  margin-top: 0;
}

/* remember to define focus styles! */
:focus {
  outline: 0;
}

/* remember to highlight inserts somehow! */
ins {
  text-decoration: none;
}
del {
  text-decoration: line-through;
}

/* tables still need 'cellspacing="0"' in the markup */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

body,
html {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

p,
h1,
h2,
a,
button,
span,
li {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -moz-user-select: none;
}

.mute-btn-div {
  z-index: 1001;
  position: absolute;
  bottom: 2px;
  right: 2px;
  width: 20px;
  height: 20px;
}

.mute-btn,
.unmute-btn {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.loading-container {
  background: url(/static/imgs/loadingScreen/6.jpg) no-repeat center center fixed;
  background-size: cover;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 100;
}

.warning-img-container {
  background: url(/static/imgs/loadingScreen/warning-min.png) no-repeat center center fixed;
  background-size: cover;
  position: absolute;
  overflow: hidden;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
}

.loading-whiteout {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgb(79, 79, 79);
  opacity: 0;
  z-index: 10;
  box-shadow: inset 0 0 100px black;
}

.poem-wrapper {
  opacity: 0;
  position: relative;
  top: 25%;
  left: 25%;
  height: 50%;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.poem-p {
  font-size: 1.2em;
  font-family: 'Caveat';
  color: white;
  text-align: center;
}

.poem-author {
  color: #ccc;
  font-size: 0.7em;
  font-style: italic;
  align-self: flex-end;
}

.enter-site {
  align-self: center;
  background-color: rgb(46, 46, 46);
  color: darkgrey;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  transition: 500ms;
  -webkit-animation-name: pulse;
  -webkit-animation-duration: 1.5s;
  -webkit-animation-iteration-count: infinite;
  animation-name: pulse;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  box-shadow: 0 0 4px white;
}

.enter-site:hover {
  background-color: rgb(160, 160, 160);
  color: rgb(0, 0, 0);
}

.enter-site:active {
  background-color: rgb(77, 77, 77);
  color: rgb(0, 0, 0);
}

.loading-container > img {
  width: auto;
  height: 100%;
}

.load-bar-container {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%) perspective(300px) rotateX(10deg);
  height: 30%;
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.load-texts {
  font-size: 1.5em;
  color: #d6faff;
  text-shadow: 0 0 5px #039bb3, 0 0 10px #039bb3, 0 0 15px #039bb3, 0 0 20px #039bb3, 0 0 30px #039bb3, 0 0 40px #039bb3,
    0 0 55px #039bb3, 0 0 75px #039bb3, 2px 2px 2px #ce5937;
  opacity: 75%;
  text-align: center;
}

.load-texts-bigger {
  font-size: 2rem !important;
}

.img-warning {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.text-warning {
  text-align: center;
  color: #ff9393;
  text-shadow: 0 0 5px #370c00, 0 0 10px #aa2735, 0 0 15px #aa2735, 0 0 20px #aa2735, 0 0 30px #aa2735, 0 0 40px #aa2735,
    0 0 55px #aa2735, 0 0 75px #aa2735, 2px 2px 2px #ce5937;
}

.load-bar {
  width: 5px;
  height: 30px;
  background-color: #00bad3;
  opacity: 75%;
  box-shadow: 0 0 5px #039bb3, 0 0 10px #039bb3, 0 0 15px #039bb3, 0 0 20px #039bb3, 0 0 30px #039bb3, 0 0 40px #039bb3,
    0 0 55px #039bb3, 0 0 75px #039bb3, 2px 2px 2px #ce5937;
}

.quality-selector {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.exit-load-btn {
  font-size: 1rem;
  border: none;
  padding: 5px 10px;
  opacity: 0.75;
  color: #d6faff;
  background-color: #74bcff;
  box-shadow: 0 0 5px #039bb3, 0 0 10px #039bb3, 0 0 15px #039bb3, 0 0 20px #039bb3, 0 0 30px #039bb3, 0 0 40px #039bb3,
    0 0 55px #039bb3, 0 0 75px #039bb3, 2px 2px 2px #ce5937;
  transition: 0.2s;
  border-radius: 5px;
}

.exit-load-btn:hover {
  opacity: 1;
}

.exp-div {
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ffffff11;
  border-radius: 1em/1em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.exp-p {
  font-size: 0.75em;
  margin: 5px 10px;
  text-align: center;
  color: #74bcff;
  font-weight: 100;
  font-family: -webkit-pictograph;
}

.canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.welcome-container {
  position: absolute;
  opacity: 0;
  left: 25%;
  top: 37.5%;
  width: 50%;
  height: 25%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.welcome-text {
  color: white;
  font-size: 2em;
  font-family: 'Caveat';
  text-align: center;
}

.scroll-down-container {
  position: relative;
  width: 15px;
  height: 45px;
  border: 1px solid white;
  border-radius: 7.5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 0px 2px rgba(255, 255, 255, 0.3);
}

.scroll-down {
  position: absolute;
  margin: 0 auto;
  width: 13px;
  height: 13px;
  background-color: white;
  border-radius: 50%;
}

.arrows-container {
  display: flex;
  flex-flow: row;
  justify-content: space-evenly;
  align-items: center;
}

.arrows-wrapper {
  height: 45px;
  width: 45px;
  border: 2px solid #ffffffff;
  border-radius: 5px;
  background: #ffffff00;
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrows {
  font-size: 1.1em;
  text-align: center;
  color: #000;
}

.touch-scroll {
  position: relative;
  height: 75px;
  width: 75px;
  margin: 35px;
}

.homepage {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 2em;
}

.home {
  text-align: center;
  display: flex;
  justify-content: center;
  margin-bottom: 3em;
}

.home-text {
  width: 100%;
  font-size: 4em;
  text-align: center;
  font-family: 'Caveat';
  color: white;
  text-shadow: 0px 0px 10px rgb(255 255 255 / 20%);
}

.content {
  position: relative;
  height: auto;
  width: 70%;
  overflow: hidden;
  font-family: 'Mate SC';
  font-size: 1em;
  line-height: 1em;
  color: #ecf0f1;
}
.content__container {
  font-weight: 600;
  overflow: hidden;
  height: 1em;
  padding: 0 1em;
}
.content__container:before {
  content: '⊷';
  float: left;
  left: 0;
  text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.2);
}
.content__container:after {
  float: right;
  content: '⊶';
  position: absolute;
  right: 0;
  text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.2);
}
.content__container:after,
.content__container:before {
  position: absolute;
  top: 0;
  color: white;
  font-size: 1.1em;
  line-height: 1em;
  -webkit-animation-name: opacity;
  -webkit-animation-duration: 2.5s;
  -webkit-animation-iteration-count: infinite;
  animation-name: opacity;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
}
.content__container__text {
  display: inline;
  float: left;
  margin: 0;
}
.content__container__list {
  margin-top: 0;
  text-align: center;
  list-style: none;
  -webkit-animation-name: change;
  -webkit-animation-duration: 10s;
  -webkit-animation-iteration-count: infinite;
  animation-name: change;
  animation-duration: 10s;
  animation-iteration-count: infinite;
}
.content__container__list__item {
  line-height: 102%;
  margin: 0;
  text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.2);
}

.whoami-container {
  position: absolute;
  top: 35%;
  height: 30%;
  left: 15%;
  width: 70%;
  display: grid;
  grid-template-columns: 0.01fr 1fr;
}

.whoami-container.spine-right {
  grid-template-columns: 1fr 0.01fr !important;
}

.whoami-wrapper {
  display: flex;
  flex-flow: column;
  justify-content: space-evenly;
}

.whoami-header {
  font-size: 3em;
  font-family: 'Caveat';
  text-align: center;
  color: white;
  text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.3);
}

.whoami-p {
  font-family: 'Montserrat';
  text-align: center;
  color: white;
  text-shadow: 0px 0px 7px rgba(255, 255, 255, 0.4);
}

.technologies-container {
  position: absolute;
  top: 5%;
  left: 0;
  width: 100%;
  height: 95%;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
}

.tech-header {
  font-family: 'Caveat';
  font-size: 3em;
  margin: 25px;
  color: white;
}

.move-mouse-div {
  position: relative;
  margin: 5% 0;
  width: 50%;
  height: 25%;
  opacity: 0;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: start;
}

.move-mouse-div > p {
  color: white;
  text-shadow: 0px 0px 2px rgba(255, 255, 255, 0.3);
  font-family: 'Montserrat';
  text-align: center;
}

.mouse-move {
  position: absolute;
  top: 50%;
  left: 25%;
  height: 15px;
  width: 15px;
  background-color: white;
  border-radius: 50%;
  box-shadow: 0px 0px 2px rgba(255, 255, 255, 0.3);
  z-index: 2;
  transform: translateY(-50%);
}

.tech-div {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  background-color: #f6dd1e;
  transform: translate(-50%, -50%);
}

.tech-div > p {
  font-weight: bold;
  color: black;
  margin: 3px;
}

.touch-click {
  margin: 35px;
  position: relative;
  height: 75px;
  width: 75px;
  z-index: 2;
}

.spine {
  padding-top: 2.5%;
  height: 95%;
  width: 3px;
  background: rgb(255, 255, 255);
  background: linear-gradient(180deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255) 50%, rgba(255, 255, 255, 0) 100%);
  border-radius: 1.5px;
  transition: 100ms;
}

.projects-container {
  position: absolute;
  top: 25%;
  height: 50%;
  left: 15%;
  width: 70%;
  display: grid;
  grid-template-columns: 0.01fr 1fr;
}

.projects-wrapper {
  display: flex;
  flex-flow: column;
  padding-left: 32px;
  justify-content: space-around;
}

.projects-header {
  font-size: 3em;
  font-family: 'Caveat';
  text-align: center;
  color: white;
  text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.3);
}

.projects-header.sub {
  font-size: 1.2em;
  font-weight: bold;
  font-family: 'Montserrat';
  margin-bottom: 16px;
}

.projects-p {
  font-family: 'Montserrat';
  text-align: center;
  color: white;
  text-shadow: 0px 0px 7px rgba(255, 255, 255, 0.4);
  margin-bottom: 8px;
}

.main-column {
  display: flex;
}

.hide {
  display: none !important;
}

.made-with-container {
  position: absolute;
  top: 10%;
  height: 80%;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-flow: column;
}
.made-with-title {
  width: 100%;
  font-family: 'Mate SC';
  color: #faeff1;
  margin: 2% 0;
  font-size: 2.25em;
  display: flex;
  flex-flow: row;
  justify-content: center;
}

.dots {
  font-family: 'Mate SC';
  font-size: inherit;
  color: rgb(242, 0, 255);
}

.made-with-title::before {
  content: '[';
  font-family: 'Montserrat';
  color: rgb(187, 238, 253);
}
.made-with-title::after {
  content: ']';
  font-family: 'Montserrat';
  color: rgb(187, 238, 253);
}

.made-with-wrapper {
  height: auto;
  display: flex;
  margin: 2% 0;
  align-items: center;
  justify-content: space-around;
}

.made-with-header {
  font-family: 'Montserrat';
  text-align: center;
  font-size: 1.25em;
  color: rgb(0, 195, 255);
  text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.2);
  margin-bottom: 20px;
}

.made-with-ul {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  width: 22%;
}

.made-with-li {
  padding: 3px;
  font-weight: 500;
  font-family: 'Montserrat';
  color: #faeff1;
  text-align: center;
  text-shadow: 0px 0px 7px rgba(255, 255, 255, 0.2);
}

.song {
  height: 1em;
  fill: rgb(0, 195, 255);
}

.made-with-li::before {
  content: '~ ';
  color: rgb(187, 238, 253);
}

a {
  font-weight: 600;
  color: #a7e4f7;
  text-decoration: none;
}
.made-with-li > a:hover {
  text-shadow: 0px 0px 7px rgba(255, 255, 255, 0.4);
}

.contact-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}

.contact-wrapper {
  margin: 25px;
  color: white;
  width: 90%;
  display: flex;
  flex-flow: column;
  align-items: flex-end;
}

.contact-header {
  margin: 25px;
  font-family: 'Caveat';
  font-size: 2.5em;
  color: white;
  text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.2);
}

.contact-h2 {
  font-size: 1.1em;
  font-weight: 600;
  font-style: italic;
  margin: 10px 0;
  text-shadow: 0px 0px 7px rgba(255, 255, 255, 0.2);
  font-family: 'Montserrat';
}

.contact-p {
  margin: 5px 0;
  font-style: italic;
  text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.2);
  font-family: 'Montserrat';
}

.contact-cursor {
  cursor: pointer;
}

.inner-touch-scroll {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: rgb(255, 255, 255);
  padding: 1.5px;
}

.inner-touch-click {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 1);
}

.touched-scroll {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 23px;
  height: 23px;
  border-radius: 50%;
  box-shadow: 0px 0px 16px rgb(255, 255, 255);
  opacity: 0;
}

.touched-click {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 23px;
  height: 23px;
  border-radius: 50%;
  box-shadow: 0px 0px 16px rgb(255, 255, 255);
  opacity: 0;
}

@-webkit-keyframes opacity {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-webkit-keyframes change {
  0%,
  10%,
  90%,
  100% {
    transform: translate3d(0, 0, 0);
  }
  20%,
  30%,
  70%,
  80% {
    trransform: translate3d(0, -33.3%, 0);
  }
  40%,
  60% {
    transform: translate3d(0, -66.6%, 0);
  }
}

@keyframes opacity {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes change {
  0%,
  10%,
  90%,
  100% {
    transform: translate3d(0, 0, 0);
  }
  20%,
  30%,
  70%,
  80% {
    trransform: translate3d(0, -33.3%, 0);
  }
  40%,
  60% {
    transform: translate3d(0, -66.6%, 0);
  }
}

@keyframes pulse {
  0%,
  100% {
    box-shadow: 0 0 3px white;
  }
  50% {
    box-shadow: 0 0 18px white;
  }
}

@-webkit-keyframes pulse {
  0%,
  100% {
    box-shadow: 0 0 3px white;
  }
  50% {
    box-shadow: 0 0 18px white;
  }
}
